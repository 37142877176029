import React, { Component } from 'react';

import { withCookies } from 'react-cookie';
import { toast } from "react-toastify";

import {schemaFieldToFormInput} from '../components/Form/utility';
import AlertDismissible from '../components/AlertDismissible';
import { NotificationUtility } from '../components/notifications';

import '../css/App.css';
import '../css/bootstrap.min.css';
import '../css/themify-icons.css';
import 'react-toastify/dist/ReactToastify.css';

import '../scss/style.scss';

import {  ApiManager,
          DataManager,
          NotificationManager,
          PushManager,
          SiteManager } from '../managers';

import Layout1 from './layout-1';
import Layout2 from './layout-2';

class App extends Component
{
  _showTitleInNav = true;
  _notificationAudioRef = null;
  _dataMgr = null;

  _layout = 2;

  // MARK: - Constructor
  constructor(props)
  {
    console.log("App()");
    super(props);
    this.state =
    {
      alert:
      {
          isShowing:      false,
          header:         '',
          detail:         '',
          dismissText:    '',
      },
      siteManager: null,
      pushManager: null,
      notificationManager: null,
      title: '',
      pageComponents: null,
      path: window.location.pathname,
      redirect: false,
      windowSize: window.innerWidth,
      selectedNavItem: -1,
      sideNavComponent: { navData: [] },
      apiManager: null,
      navListIsShowing: 'responsive',
    }

    // Refs
    this._notificationAudioRef = React.createRef();

    // Notifications
    toast.configure();

    // Handle resize (layout 1 only)
    window.addEventListener('resize', () =>
    {
      this.setState({ windowSize: window.innerWidth });
    });
  }

  // because this is async, render() will be called before this completes
  async componentWillMount()
  {
    console.log('App.componentWillMount()');

    // Initiate API manager
    const apiManager = ApiManager.Init(this.props.cookies.get('token'));
    this.setState({ apiManager: apiManager });

    // Setup data manager
    this._dataMgr = DataManager.GetInstance(this.showAlert);
    this._dataMgr.initDataStore(this.props.cookies);

    // Initialize site manager
    let siteManager = await SiteManager.GetInstance(this.props.cookies);
    console.log("App.Obtained SiteManager");

    // Build page/form input map
    const pageComponents = new Map();
    const pageNames = siteManager.getPageNames();
    for(let i = 0; i < pageNames.length; i++)
    {
      let formComponentIdx = -1;
      const formInputs = [];
      const page = siteManager.getPageData(pageNames[i]);

      // Iterate components in page and pre-process them
      let formComponent = null;
      for(let i = 0; i < page.components.length; i++)
      {
        // Handle form
        if(page.components[i].type === 'form')
        {
          // Now iterate schema fields and convert to form inputs
          for(let j = 0; j < page.components[i].form.length; j++)
          {
            const formInput = await schemaFieldToFormInput(page.components[i].form[j]);
            formInputs.push(formInput);
          }

          page.components[i].form = formInputs;
        }
      }

      pageComponents.set(page.name, page.components);
    }

    // Kick off notification manager
    const notificationManager = await NotificationManager.GetInstanceA(this.props.cookies.get('token'));
    notificationManager.addObserver(this);

    // Push notifications
    const pushManager = await PushManager.GetInstance(this.props.cookies.get('token'));

    this.setState(
    {
      siteManager: siteManager,
      title: siteManager.getFrontendTitle(),
      pageComponents: pageComponents,
      notificationManager: notificationManager,
      pushManager: pushManager
    });
  }

  async componentDidMount()
  {
    console.log('App.componentDidMount()');
  }

// MARK: - Helpers
/**
   Alert box that can be dismissed
   @param   {bool}      show            To show/hide alert
   @param 	{string} 	header 			Title text
   @param 	{string}	detail 			Description text
   @param 	{string}	dismissText 	Dismiss button text
   @param   {string}    variant         Variant type { 'primary','secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark' }
  */
  showAlert = (show,
              header = '',
              detail = '',
              variant = 'success',
              dismissText = 'Dismiss') =>
  {
    this.setState({ alert:
    {
      isShowing:      show,
      header:         header.toString(),
      detail:         detail.toString(),
      variant:        variant,
      dismissText:    dismissText
    }});
  }

  updateMasterState = (state, cb = null) =>
  {
    this.setState(state, () =>
    {
      if(cb !== null)
      {
        cb();
      }
    });
  }

  // MARK: - Page  controller related
  reloadPageData = () =>
  {
    this.forceUpdate();
  }

  // MARK: - Notification manager observer related
  newNotification = (notification) =>
  {
    console.log('App.newNotification()');
    //console.log(notification);
    this._notificationAudioRef.current.muted = "";
    try
    {
      // TODO: Create a sound icon on the nav bar they can toggle to enable this
      //this._notificationAudioRef.current.play();
    }
    catch(err)
    {
      console.log("Cannot play sound until user interacts with webpage");
    }

    // If we're on the messages page we let that handle it's own notifications
    // so we don't annoyingly spam an active conversation
    if(window.location.pathname.indexOf('/messages') === -1)
    {
      toast(NotificationUtility.parseNotificationBody(notification),
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () =>
        {
          const forwardUrl = '/view/notification/' + NotificationUtility.base64EncodeUnicode(notification._id.toString()) + '/';
          this.forward(forwardUrl);
        }
      });
    }
  }

  forward = (forwardUrl) =>
  {
    console.log('App.setting redirect');
    this.setState({ redirect: forwardUrl });
  }

  // MARK; - Render
  shouldComponentUpdate(nextProps, nextState)
  {
    return (this.state.title !== nextState.title ||
            this.state.pageComponents !== nextState.pageComponents ||
            this.state.alert.isShowing !== nextState.alert.isShowing ||
            this.state.path !== nextState.path ||
            this.state.pushManager !== nextState.pushManager ||
            this.state.redirect !== nextState.redirect ||
            this.state.notificationManager !== nextState.notificationManager ||
            this.state.selectedNavItem !== nextState.selectedNavItem ||
            this.state.apiManager !== nextState.apiManager ||
            this.state.navListIsShowing !== nextState.navListIsShowing
    );
  }

  componentDidUpdate()
  {
    if(this.state.redirect)
    {
      console.log('App.clearing redirect');
      this.setState({ redirect: false });
    }
    //console.log(this.state.path + " == " + window.location.pathname);
    if(this.state.path !== window.location.pathname)
    {
      this.setState({ path: window.location.pathname });
    }
  }

  render()
  {
    let components = this.state.pageComponents !== null ? this.state.pageComponents.get('home') : [];
    let sideNavComponent = null;
    if(components)
    {
      components = [...components];
      for(let i = 0; i < components.length; i++)
      {
        if(components[i].type === 'side-nav')
        {
          sideNavComponent = components[i];
          break;
        }
      }
    }

    console.log('App.render()');
    let alertDismissible;
    if(this.state.alert.isShowing)
    {
      alertDismissible = <AlertDismissible header={this.state.alert.header}
       detail={this.state.alert.detail}
       dismissText={this.state.alert.dismissText}
       show={this.state.alert.isShowing}
       variant={this.state.alert.variant}
       dismissCb={this.showAlert}/>;
    }

    const isMobile = this.state.windowSize <= 768;
    const token = this.props.cookies.get('token');

    if(this._layout === 1)
    {
      return (
        <Layout1
          appLayout={this._layout}
          title={this.state.title}
          siteManager={this.state.siteManager}
          selectedNavItem={this.state.selectedNavItem}
          showTitleInNav={this._showTitleInNav}
          showAlert={this.showAlert}
          token={token}
          notificationManager={this.state.notificationManager}
          pushManager={this.state.pushManager}
          path={this.state.path}
          updateMasterState={this.updateMasterState}
          forward={this.forward}
          cookies={this.props.cookies}
          isMobile={isMobile}
          reloadPageData={this.reloadPageData}
          redirect={this.state.redirect}
          pageComponents={this.state.pageComponents}
          alertDismissible={alertDismissible}
          components={components}
          notificationAudioRef={this._notificationAudioRef}
          navListIsShowing={this.state.navListIsShowing}
        />
      );
    }
    else
    {
      return (
        <Layout2
          appLayout={this._layout}
          title={this.state.title}
          siteManager={this.state.siteManager}
          selectedNavItem={this.state.selectedNavItem}
          showTitleInNav={this._showTitleInNav}
          showAlert={this.showAlert}
          token={token}
          notificationManager={this.state.notificationManager}
          pushManager={this.state.pushManager}
          path={this.state.path}
          updateMasterState={this.updateMasterState}
          forward={this.forward}
          cookies={this.props.cookies}
          isMobile={isMobile}
          reloadPageData={this.reloadPageData}
          redirect={this.state.redirect}
          pageComponents={this.state.pageComponents}
          alertDismissible={alertDismissible}
          components={components}
          notificationAudioRef={this._notificationAudioRef}
          navListIsShowing={this.state.navListIsShowing}
        />
      );
    }
  }
}

/**
  withCookies will inject the cookies object as a prop into App.
  We can then access this.props.cookies within App
*/
export default withCookies(App);
